<template>
  <div class="heart" :style="style">
    <font-awesome-icon :icon="icon" :style="{color: color}"/>
  </div>
</template>

<script>
  export default {
    data() {
      const size = Math.round(200 + Math.random() * 200);
      const duration = Math.round(15 + Math.random() * 20);
      const delay = Math.round(Math.random() * duration);
      const xOffset = Math.round(Math.random() * 200 - 100);

      return {
        size,
        duration,
        delay,
        xOffset,
        icon: 'heart',
        color: '#8200a1'
      };
    },
    created() {
      const today = new Date();
      const month = today.getMonth() + 1;
      const day = today.getDate();

      // International Women's day
      if (month == 3 && day == 8) {
        const icons = ['balance-scale', 'venus'];
        this.icon = icons[Math.floor((Math.random()*icons.length))];

      // Halloween
      } else if (month == 10 && day == 31) {
        const icons = ['broom', 'ghost', 'spider'];
        this.icon = icons[Math.floor((Math.random()*icons.length))];
        this.color = 'orange';

      // World AIDS day
      } else if (month == 12 && day == 1) {
        this.icon = 'ribbon';
        this.color = 'red';

      // Christmas
      } else if (month == 12 && (day == 24 || day == 25)) {
        if (Math.random() < 0.3) {
          this.icon = 'candy-cane';
          this.color = 'red';
        } else {
          this.icon = 'tree';
          this.color = 'green';
        }
      }
    },
    computed: {
      style() {
        const animationName = ['bg-float1', 'bg-float2', 'bg-float3'][Math.floor(Math.random() * 3)];

        return {
          fontSize: this.size + 'px',
          animationName: animationName,
          animationDuration: this.duration + 's',
          animationTimingFunction: 'linear',
          animationIterationCount: 'infinite',
          animationDelay: -this.delay + 's',
          left: this.xOffset + 'vw'
        };
      }
    }
  }
</script>

<style scoped>
.heart {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.3;
}
</style>

<style>
@keyframes bg-float1 {
  from {
    transform: translate(100vw, 100vh);
  }

  to {
    transform: translate(-400px, -400px);
  }
}

@keyframes bg-float2 {
  from {
    transform: translate(110vw, 100vh);
  }

  to {
    transform: translate(-400px, -400px);
  }
}

@keyframes bg-float3 {
  from {
    transform: translate(90vw, 100vh);
  }

  to {
    transform: translate(-400px, -400px);
  }
}
</style>
