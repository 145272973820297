<template>
  <div class="login">
    <h1>Login</h1>

    <form @submit.prevent="submit">
      <input
        v-model="username"
        type="text"
        placeholder="Name"
        required
        ref="username"
        autocapitalize="off"
        autocorrect="off"
        spellcheck="false"
      >
      <input
        v-model="password"
        type="password"
        placeholder="Password"
        required
      >

      <p v-if="error" class="error">
        {{ error }}
      </p>

      <button :disabled="loading || !username || !password">
        <font-awesome-icon v-if="loading" icon="spinner" pulse/>
        <template v-else>Login</template>
      </button>
    </form>

    <div class="demo">
      Don't have an account?<br>
      Try out the
      <a @click.prevent="loginDemo">demo</a>
      account or
      <router-link to="/register">register</router-link>.
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      error: null,
      loading: false
    }
  },
  mounted() {
    this.$refs.username.focus();
  },
  methods: {
    loginDemo() {
      this.username = 'demo';
      this.password = 'demodemo';
      this.submit();
    },
    submit() {
      this.loading = true;

      this.$api.post('/login', {
        username: this.username,
        password: this.password
      }).then(response => {
        this.error = null;
        this.loading = false;

        this.$store.commit('setLoggedIn', true);
        this.$store.commit('setSettings', response.data);
        this.$router.push({name: 'dashboard'});
      }).catch(error => {
        if (error.response && error.response.status == 401) {
          this.error = error.response.data.error;
        } else {
          this.error = error.message;
        }
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
.login {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 1em auto;
  padding: 0 1em;
  box-sizing: border-box;
  background-color: #1e282f;
}

h1 {
  text-align: center;
}

input, button {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1em;
}

a {
  color: #dd57ff;
  text-decoration: none;
  cursor: pointer;
}

.demo {
  text-align: center;
  margin-bottom: 0.5em;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
}
</style>
