<template>
  <div class="diary-day-selector">
    <font-awesome-icon v-if="loading" icon="spinner" pulse/>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <div
      v-else
      v-for="entry in entries"
      :key="entry.date"
      @click="selectDate(entry.date)"
      :class="{entry: true, active: selectedDate == entry.date}"
    >
      <div class="info">
        <div class="date">{{ entry.date | date({timeZone: 'UTC'}) }}</div>

        <div class="stats">
          <font-awesome-icon icon="clock"/> {{ entry.surviveSeconds | duration }}<br>
          <font-awesome-icon icon="shoe-prints"/> {{ entry.steps | numberWithCommas }}<br>
          <template v-if="entry.meter.min && entry.meter.max">
            <font-awesome-icon icon="chart-line"/>
            <template v-if="entry.meter.min == entry.meter.max">
              {{ entry.meter.min }}
            </template>
            <template v-else>
                {{ entry.meter.min }}&ndash;{{ entry.meter.max }}
            </template>
          </template>
        </div>
      </div>

      <TimingBar :counts="entry.timing"/>
    </div>
  </div>
</template>

<script>
import TimingBar from '@/components/TimingBar.vue';
import FormatMixin from '@/utils/FormatMixin';

export default {
  mixins: [FormatMixin],
  components: {
    TimingBar
  },
  props: {
    month: String
  },
  data() {
    return {
      entries: [],
      selectedDate: null,
      loading: false,
      error: null
    };
  },
  watch: {
    month() {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      const url = '/diary/' + this.month;
      this.$api.get(url).then(response => {
        this.entries = response.data.entries.reverse();
        this.selectDate(this.entries[0].date);
        this.loading = false;
      }).catch(error => {
        this.error = error.message;
        this.loading = false;
      });
    },
    selectDate(date) {
      this.selectedDate = date;
      this.$emit('date-selected', date);
    }
  },
  filters: {
    duration(seconds) {
      var s = seconds % 60;
      var m = Math.floor(seconds / 60);
      if (m >= 60) {
        var h = Math.floor(m / 60);
        m %= 60;
        return String(h) + ':' + String(m).padStart(2, '0') + ':' + String(s).padStart(2, '0');
      } else {
        return String(m).padStart(2, '0') + ':' + String(s).padStart(2, '0');
      }
    },
    numberWithCommas(n) {
      return String(n).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }
}
</script>

<style scoped>
.diary-day-selector {
  border-bottom: 1px solid black;
}

.entry {
  padding: 0.3em 0.5em;
  cursor: pointer;
  background-color: #0a141b;
  color: #cccccc;
}
.entry.active {
  background-color: #4c565d;
  color: white;
}

.entry > .info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.entry > .info > .date {
  flex: 0 0 auto;
  font-family: "Miso Bold";
  font-size: 130%;
}

.entry > .info > .stats {
  flex: 1 0 auto;
  text-align: right;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
}

@media (max-width: 1200px) {
  .diary-day-selector {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
  }
  .entry {
    flex: 0 0 250px;
  }
}
</style>
