<template>
  <div class="settings">
    <h1>Settings</h1>

    <form @submit.prevent="submit">
      <p>
        Name: {{ username }}
      </p>
      <label>
        New Password
        <input
          v-model="password"
          type="password"
          minlength="8"
        >
      </label>
      <label>
        New Password (again)
        <input
          v-model="passwordAgain"
          type="password"
          minlength="8"
        >
      </label>
      <label>
        Email
        <input
          v-model="email"
          type="email"
          required
        >
      </label>
      <label>
        Translate Song Titles
        <select v-model="translit">
          <option :value="true">Transliterate</option>
          <option :value="false">Native Language</option>
        </select>
      </label>

      <p v-if="error" class="error">
        {{ error }}
      </p>

      <button :disabled="loading">
        <font-awesome-icon v-if="loading" icon="spinner" pulse/>
        <template v-else>Save</template>
      </button>
    </form>

    <button @click="deleteAccount" class="delete">
      Delete Account
    </button>
  </div>
</template>

<script>
export default {
  data() {
    const settings = this.$store.state.settings;

    return {
      username: settings.username,
      email: settings.email,
      password: '',
      passwordAgain: '',
      translit: settings.translit,
      error: null,
      loading: false
    }
  },
  methods: {
    submit() {
      if (this.password != this.passwordAgain) {
        this.error = 'Passwords don\'t match';
        return;
      }

      this.loading = true;

      const settings = {
        username: this.username,
        email: this.email,
        translit: this.translit,
      };
      if (this.password)
        settings.newPassword = this.password;

      this.$api.post('/settings', settings).then(response => {
        this.error = null;
        this.loading = false;

        this.$store.commit('setSettings', response.data);
      }).catch(error => {
        if (error.response && error.response.status == 403) {
          this.error = error.response.data.error;
        } else {
          this.error = error.message;
        }
        this.loading = false;
      });
    },
    deleteAccount() {
      if (confirm('Do you want to delete your account?')) {
        this.$api.delete('/settings').then(() => {
            this.$store.commit('setLoggedIn', false);
            this.$store.commit('clearSettings');
            this.$router.push({name: 'login'});
        }).catch(error => {
          if (error.response && error.response.status == 403) {
            this.error = error.response.data.error;
          } else {
            this.error = error.message;
          }
          this.loading = false;
        });
      }
    }
  }
}
</script>

<style scoped>
.settings {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 1em auto;
  padding: 0 1em;
  box-sizing: border-box;
  background-color: #1e282f;
}

h1 {
  text-align: center;
}

input, select, button {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1em;
}

button.delete {
  background-color: #ff3030;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
}
</style>
