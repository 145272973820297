<template>
  <div class="upload">
    <h1>Upload</h1>

    <form @submit.prevent="submit">
      <div class="info">
        <font-awesome-icon icon="info-circle"/>
        You can select multiple files at once.
      </div>

      <label>
        Files
        <input
          type="file"
          accept=".json,application/json"
          multiple
          :disabled="loading"
          @change="setFiles($event.target.files)"
        />
      </label>
      <div v-if="!files.length" class="info">
        This upload form expects
        <router-link to="/faq">custom scores</router-link>
        from the Simply Love Theme. In order to convert and upload your
        existing scores use the
        <router-link to="/upload/convert">conversion tool</router-link>.
      </div>
      <template v-if="files.length">
        <div v-if="uploadDone" class="done">
          <font-awesome-icon icon="check"/>
          Upload complete!
        </div>
        <progress
          v-else-if="loading"
          :value="uploadIdx"
          :max="files.length"
        >
          {{ uploadIdx }}/{{ files.length }}
        </progress>
        <button v-else>Upload</button>
        <div
          v-for="error in uploadErrors"
          :key="error"
          class="error"
        >
          {{ error }}
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import FormatMixin from '@/utils/FormatMixin';

export default {
  mixins: [FormatMixin],
  data() {
    return {
      files: [],
      loading: false,
      uploadIdx: null,
      uploadErrors: [],
      uploadDone: false
    }
  },
  methods: {
    setFiles(files) {
      this.files = files;
      this.uploadErrors = [];
      this.uploadDone = false;
    },
    async readFile(file) {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
        reader.onerror = (ev) => {
          reject(ev.target.error);
        };
      });

      return await promise;
    },
    async submit() {
      this.loading = true;

      for (this.uploadIdx = 0; this.uploadIdx < this.files.length; this.uploadIdx++) {
        var data;
        try {
          const text = await this.readFile(this.files[this.uploadIdx]);
          data = JSON.parse(text);
        } catch (error) {
          this.uploadErrors.push('Failed to load file ' + this.files[this.uploadIdx].name + ': ' + error);
          continue;
        }

        try {
          await this.$api.post('/upload', data);
        } catch (error) {
          var message;
          if (error.response && error.response.status == 403) {
            message = error.response.data.error;
          } else {
            message = error.message;
          }

          this.uploadErrors.push('Failed to upload ' + this.files[this.uploadIdx].name + ': ' + message);
        }
      }

      this.uploadDone = true;
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.upload {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 1em auto;
  padding: 0 1em;
  box-sizing: border-box;
  background-color: #1e282f;
}

h1 {
  text-align: center;
}

.info {
  text-align: center;
  margin-bottom: 1em;
}

input, button {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1em;
}

a {
  color: #dd57ff;
  text-decoration: none;
  cursor: pointer;
}

progress {
  appearance: none;
  margin-bottom: 1em;
  width: 100%;
  color: #8200a1;
  background-color: #182025;
  border: 1px solid #444444;
}
progress::-webkit-progress-bar {
  background-color: #8200a1;
}
progress::-moz-progress-bar {
  background-color: #8200a1;
}

.done {
  margin-bottom: 1em;
  text-align: center;
  font-family: 'Wendy';
  font-size: 120%;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
  margin-bottom: 1em;
}
</style>
