<template>
  <div id="app">
    <nav>
      <router-link to="/" class="logo">
        <img src="/static/logo.png">
      </router-link>
      <a @click.prevent="expand" class="expand">
        <font-awesome-icon icon="bars"/>
      </a>
      <div class="items" ref="items">
        <template v-if="$store.state.loggedIn">
          <router-link to="/">Dashboard</router-link>
          <router-link to="/diary">Diary</router-link>
          <router-link to="/charts">Charts</router-link>
          <router-link to="/scores">Scores</router-link>
          <router-link to="/upload">Upload</router-link>
          <span class="spacer"/>
          <router-link to="/faq">FAQ</router-link>
          <router-link to="/settings" class="settings" title="Settings">
            <font-awesome-icon icon="sliders-h"/>
          </router-link>
          <a @click.prevent="logout" class="logout" :title="'Log out ' + $store.state.settings.username">
            <font-awesome-icon icon="power-off"/>
          </a>
        </template>
        <template v-else>
          <span class="spacer"/>
          <router-link to="/login">Login</router-link>
          <router-link to="/register">Register</router-link>
          <router-link to="/faq">FAQ</router-link>
        </template>
      </div>
    </nav>

    <router-view id="content"/>

    <MovingHeart v-for="idx in 20" :key="idx"/>
  </div>
</template>

<script>
  import MovingHeart from '@/components/MovingHeart.vue';

  export default {
    components: {
      MovingHeart
    },
    watch: {
      $route() {
        const items = this.$refs.items;
        items.style.display = null;
      }
    },
    methods: {
      expand() {
        const items = this.$refs.items;
        if (items.style.display == 'block') {
          items.style.display = null;
        } else {
          items.style.display = 'block';
        }
      },
      logout() {
        this.$api.post('/logout').then(() => {
          this.$store.commit('setLoggedIn', false);
          this.$store.commit('clearSettings');
          this.$router.push({name: 'login'});
        });
      }
    }
  }
</script>

<style scoped>
nav {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  background-color: black;
  border-bottom: 1px solid #444444;
  width: 100%;
}

nav .items {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

nav a:not(.logo) {
  font-family: "Miso Bold";
  font-size: 120%;
  text-decoration: none;
  color: white;
  padding: 0.5em;
}

nav a:not(.logo).router-link-exact-active {
  background-color: #4c565d;
}

nav .spacer {
  flex-grow: 1;
}

nav .logout,
nav .settings {
  font-size: 110%;
  cursor: pointer;
}

nav .expand {
  display: none;
}

@media (max-width: 960px) {
  nav {
    display: block;
  }

  nav .items {
    display: none;
  }

  nav a:not(.logo) {
    display: block;
  }

  nav .expand {
    display: block;
    float: right;
    font-size: 110%;
    cursor: pointer;
  }
}
</style>

<style>
body {
  font-family: Miso;
  font-size: 120%;
  margin: 0;
  height: 100vh;
  background-color: black;
  color: white;
}

input {
  font-family: Miso;
  font-size: 100%;
}

select {
  font-family: sans-serif;
  font-size: 80%;
}

input, select {
  border: none;
  background-color: #182025;
  border: 1px solid #444444;
  color: white;
  padding: 0.3em;
}

input:focus,
select:focus {
  outline: 2px solid #8200a1;
}

input::placeholder {
  color: #cccccc;
}

button {
  background-color: #8200a1;
  border: 1px solid #444444;
  color: white;
  padding: 0.5em;
  cursor: pointer;
}

button:enabled:hover {
  background-color: #dd57ff;
}

button:disabled {
  background-color: #4c565d;
  color: #cccccc;
}

button:focus {
  outline: none;
}

#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#content {
  flex: 0 1 auto;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}

#content::-webkit-scrollbar {
  width: 0.3em;
}
#content::-webkit-scrollbar-thumb {
  background: #555
}
#content::-webkit-scrollbar-track {
  background: transparent;
}
</style>
