import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const defaultSettings = {
  username: null,
  email: null,
  translit: false
};

const store = new Vuex.Store({
  state: {
    settings: defaultSettings,
    loggedIn: false
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    },
    clearSettings(state) {
      state.settings = defaultSettings;
    },
    setLoggedIn(state, yesno) {
      state.loggedIn = yesno;
    }
  }
});

export default store;
