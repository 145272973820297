<template>
  <span class="zero-padded-number"><!--
    --><span v-for="idx in zerosNeeded" :key="'zero' + idx" class="zero">0</span><!--
    --><span v-for="(c, idx) in number.toString()" :key="'digit' + idx" class="digit">{{ c }}</span><!--
  --></span>
</template>

<script>
export default {
  props: {
    number: Number,
    digits: Number
  },
  data() {
    return {
      expandedScore: null,
      loading: false,
      error: null
    };
  },
  computed: {
    zerosNeeded() {
      return this.digits - this.number.toString().length;
    }
  },
}
</script>

<style scoped>
.zero-padded-number {
  font-variant-numeric: tabular-nums;
  display: inline-block;
  white-space: nowrap;
}

.digit,
.zero {
  font-family: Wendy;
  display: inline-block;
  width: 0.4em;
  text-align: right;
  font-size: 200%;
}

.zero {
  color: #444444;
}
</style>
