export default {
  filters: {
    date(date, options) {
      const d = new Date(date);
      return d.toLocaleString('en-US', {month: 'short', day: 'numeric', ...options});
    },
    time(date, options) {
      const d = new Date(date);
      return d.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: false, ...options});
    },
    translateGrade(grade) {
      return {
        Tier01: '★★★★',
        Tier02: '★★★',
        Tier03: '★★',
        Tier04: '★',
        Tier05: 'S+',
        Tier06: 'S',
        Tier07: 'S-',
        Tier08: 'A+',
        Tier09: 'A',
        Tier10: 'A-',
        Tier11: 'B+',
        Tier12: 'B',
        Tier13: 'B-',
        Tier14: 'C+',
        Tier15: 'C',
        Tier16: 'C-',
        Tier17: 'D',
        Failed: 'F'
      }[grade];
    },
    translateDifficulty(difficulty) {
      return {
        Beginner: 'Beginner',
        Easy: 'Easy',
        Medium: 'Medium',
        Hard: 'Hard',
        Challenge: 'Expert',
        Edit: 'Edit'
      }[difficulty];
    },
    translateStepsType(stepsType) {
      return {
        'dance-single': 'Single',
        'dance-double': 'Double',
        'dance-couple': 'Couple'
      }[stepsType];
    },
    formatBPM(bpm, music_rate) {
      if (!bpm.low)
        return '?';

      music_rate = music_rate ? music_rate : 1;
      const low = Math.round(bpm.low * music_rate);
      const high = Math.round(bpm.high * music_rate);

      var result = low.toString();
      if (low != high)
        result += '–' + high;
      if (bpm.random)
        result += '*';

      return result
    }
  }
}
