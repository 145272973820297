<template>
  <table class="score-table">
    <thead>
      <tr>
        <th rowspan="2" class="shrink">
          <template v-if="showDate">
            Date<br>
          </template>
          Time
        </th>
        <th class="expand">Song</th>
        <th class="expand wide">Pack</th>
        <th class="shrink">BPM</th>
        <th class="shrink wide">Modifiers</th>
        <th class="shrink wide">Timing</th>
        <th class="shrink">Combo</th>
        <th class="shrink wide">Grade</th>
        <th class="shrink wide">%</th>
        <th class="shrink narrow">Grade<br>%</th>
      </tr>
    </thead>

    <tbody>
      <template v-for="(score, idx) in scores">
        <tr
          :key="'row-' + score.guid"
          class="clickable"
          @click="expand(score.guid)"
        >
          <td class="shrink align-center">
            <template v-if="showDate">
              {{ score.datetime | date({year: 'numeric'}) }}<br>
            </template>
            {{ score.datetime | time }}
          </td>
          <td class="expand">
            <template v-if="score.song">
              {{ translit ? score.song.translit_title : score.song.title }}
              <template v-if="score.song.subtitle">
                <br>
                <small>
                  {{ translit ? score.song.translit_subtitle : score.song.subtitle }}
                </small>
              </template>
            </template>
            <template v-else-if="score.course">
              {{ translit ? score.course.translit_full_title : score.course.full_title }}
            </template>
            <br>
            {{ score.difficulty | translateDifficulty }} {{ score.meter}}
            {{ score.stepsType | translateStepsType }}
          </td>
          <td class="expand wide">
            <template v-if="score.song">
              {{ score.song.pack }}
            </template>
            <template v-else-if="score.course">
              <em>Marathon</em>
            </template>
            <template v-else>
              <em>Unknown</em>
            </template>
          </td>
          <td class="shrink align-center">
            {{ score.bpm | formatBPM(score.musicRate) }}
          </td>
          <td class="shrink wide">
            {{ score.modifiers }}
          </td>
          <td class="shrink wide">
            <div style="width: 200px;">
              <TimingBar :counts="score.tapNoteScores"/>
            </div>
          </td>
          <td class="shrink align-right" v-html="combo(score)"></td>
          <td
            class="shrink align-center wide"
            :style="(score.grade == 'Failed') ? {color: judgments.colors.miss} : {}"
          >
            {{ score.grade | translateGrade }}
          </td>
          <td class="shrink align-right wide">
            {{ (score.percentDP * 100).toFixed(2) }}
          </td>
          <td
            class="shrink align-center narrow"
            :style="(score.grade == 'Failed') ? {color: judgments.colors.miss} : {}"
          >
            {{ score.grade | translateGrade }}<br>
            {{ (score.percentDP * 100).toFixed(2) }}
          </td>
        </tr>
        <tr
          :key="'details-' + score.guid"
          class="details"
          v-if="expandedScore == score.guid"
        >
          <td colspan="9">
            <ScoreDetails
              :score="score"
              @score-removed="$delete(scores, idx)"
              @score-rerated="rerate"
            />
          </td>
        </tr>
      </template>
      <tr v-if="!scores.length">
        <td colspan="9" class="align-center">
          <em>Nothing to see here.</em>
        </td>
      </tr>
      <slot></slot>
    </tbody>
  </table>
</template>

<script>
import judgments from '@/utils/judgments';
import FormatMixin from '@/utils/FormatMixin';
import ScoreDetails from '@/components/ScoreDetails.vue';
import TimingBar from '@/components/TimingBar.vue';

export default {
  mixins: [FormatMixin],
  components: {
    ScoreDetails,
    TimingBar
  },
  props: {
    scores: Array,
    showDate: Boolean
  },
  created() {
    this.judgments = judgments;
  },
  data() {
    return {
      expandedScore: null,
      loading: false,
      error: null
    };
  },
  computed: {
    translit() {
      return this.$store.state.settings.translit;
    }
  },
  methods: {
    expand(guid) {
      if (this.expandedScore == guid) {
        this.expandedScore = null;
      } else {
        this.expandedScore = guid;
      }
    },
    combo(score) {
      const tns = score.tapNoteScores;
      var combo;

      if (tns.decent || tns.wayOff || tns.miss) {
        combo = score.maxCombo;
      } else if (tns.great) {
        combo = 'FC';
        if (tns.great < 15)
          combo += '<br>' + tns.great + ' Great';
      } else if (tns.excellent) {
        combo = 'FEC';
        if (tns.excellent < 15)
          combo += '<br>' + tns.excellent + ' Ex';
      } else {
        combo = 'FFC';
      }

      return combo;
    },
    rerate(target) {
      if (target.steps) {
        for (const score of this.scores) {
          if (score.steps && score.steps.hash == target.steps.hash)
            score.meter = target.meter;
        }
      } else if (target.trail) {
        for (const score of this.scores) {
          if (score.trail && score.trail.hash == target.trail.hash)
            score.meter = target.meter;
        }
      }
    }
  }
}
</script>

<style scoped>
table.score-table {
  width: 100%;
  border-spacing: 0;
}

thead {
  color: white;
}

thead > tr > th {
  white-space: nowrap;
  border-bottom: 2px solid black;
  background-color: #1e282f;
  padding: 0.2em 0.5em;
  top: 0;
  position: sticky;
  z-index: 1;
}

tbody > tr {
  background-color: #1e282f;
}

tbody > tr:nth-child(odd) {
  background-color: #182025;
}

tbody > tr.clickable:hover {
  background-color: #4c565d;
  cursor: pointer;
}

tbody > tr.details {
  background-color: transparent;
}

tbody > tr > td {
  padding: 0.5em 0.5em;
}

.shrink {
  white-space: nowrap;
  width: 0%;
}

.expand {
  width: 50%;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

td > a {
  display: block;
}

.narrow {
  display: none;
}

@media (max-width: 960px) {
  .narrow {
    display: table-cell;
  }
  .wide {
    display: none;
  }
}
</style>
