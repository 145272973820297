<template>
  <div class="score-details">
    <div class="centered">
      <div class="title">
        {{ fullTitle }}
      </div>
      <div class="pack">
        <template v-if="score.song">
          {{ score.song.pack }}
        </template>
        <template v-else-if="score.course">
          <em>Marathon</em>
        </template>
      </div>
      <div class="bpm">
        {{ score.bpm | formatBPM }}
        <template v-if="score.musicRate != 1">
          &times; {{ score.musicRate }} &rarr;
          {{ score.bpm | formatBPM(score.musicRate) }}
        </template>
        bpm
      </div>
    </div>
    <div class="left">
      <div class="meter" :style="{backgroundColor: difficultyColor}">
        <template v-if="rerating">
          <input
            @keypress.enter.prevent="submitRerate"
            @keyup.esc.prevent="cancelRerate"
            v-model.number="newMeter"
            ref="meter"
            type="number"
            min="1"
            max="30"
            required
          >
        </template>
        <template v-else>
          {{ score.meter }}
        </template>
      </div>
      <div class="chart-info">
        <template v-if="score.steps">
          <div>{{ score.steps.author_credit }}</div>
          <div v-if="score.steps.description && score.steps.author_credit != score.steps.description">
            {{ score.steps.description }}
          </div>
        </template>
        <template v-else>
          <div v-if="score.course.scripter">
            {{ score.course.scripter }}
          </div>
          <div v-if="score.course.description">
            {{ score.course.description }}
          </div>
        </template>
        <div>
          {{ score.difficulty | translateDifficulty }} /
          {{ score.stepsType | translateStepsType }}
        </div>
        <div v-if="score.meter != score.originalMeter">
          (Original rating: {{ score.originalMeter }})
        </div>
      </div>
      <div class="rerate">
        <a href="#" v-if="rerating" @click.prevent="submitRerate">
          <font-awesome-icon icon="check"/>
          Save
        </a>
        <font-awesome-icon v-else-if="reratingSubmitting" icon="spinner" pulse/>
        <a href="#" @click.prevent="rerate" v-else>
          <font-awesome-icon icon="edit"/>
          Re-rate
        </a>
      </div>
    </div>
    <div class="score-info">
      <div class="note-scores">
        <table class="column">
          <tr>
            <td colspan="2" class="percent-dp">
              {{ (score.percentDP * 100).toFixed(2) }}
            </td>
          </tr>
          <tr>
            <th class="align-right">holds</th>
            <td class="nowrap">
              <ZeroPaddedNumber :digits="otherDigits" :number="score.radarActual.holds"/><!--
              --><span class="slash">/</span><!--
              --><ZeroPaddedNumber :digits="otherDigits" :number="score.radarPossible.holds"/>
            </td>
          </tr>
          <tr>
            <th class="align-right">mines</th>
            <td class="nowrap">
              <ZeroPaddedNumber :digits="otherDigits" :number="score.radarActual.mines"/><!--
              --><span class="slash">/</span><!--
              --><ZeroPaddedNumber :digits="otherDigits" :number="score.radarPossible.mines"/>
            </td>
          </tr>
          <tr>
            <th class="align-right">hands</th>
            <td class="nowrap">
              <ZeroPaddedNumber :digits="otherDigits" :number="score.radarActual.hands"/><!--
              --><span class="slash">/</span><!--
              --><ZeroPaddedNumber :digits="otherDigits" :number="score.radarPossible.hands"/>
            </td>
          </tr>
          <tr>
            <th class="align-right">rolls</th>
            <td class="nowrap">
              <ZeroPaddedNumber :digits="otherDigits" :number="score.radarActual.rolls"/><!--
              --><span class="slash">/</span><!--
              --><ZeroPaddedNumber :digits="otherDigits" :number="score.radarPossible.rolls"/>
            </td>
          </tr>
        </table>
        <table class="column">
          <tr>
            <th :style="{color: judgments.colors.blueFantastic}" class="align-right">
              {{ judgments.labels.blueFantastic.toUpperCase() }}
            </th>
            <td>
              <ZeroPaddedNumber :digits="judgmentDigits" :number="score.tapNoteScores.blueFantastic"/>
            </td>
          </tr>
          <tr v-if="score.gameMode == 'FA+'">
            <th :style="{color: judgments.colors.whiteFantastic}" class="align-right">
              {{ judgments.labels.whiteFantastic.toUpperCase() }}
            </th>
            <td>
              <ZeroPaddedNumber :digits="judgmentDigits" :number="score.tapNoteScores.whiteFantastic"/>
            </td>
          </tr>
          <tr>
            <th :style="{color: judgments.colors.excellent}" class="align-right">
              {{ judgments.labels.excellent.toUpperCase() }}
            </th>
            <td>
              <ZeroPaddedNumber :digits="judgmentDigits" :number="score.tapNoteScores.excellent"/>
            </td>
          </tr>
          <tr>
            <th :style="{color: judgments.colors.great}" class="align-right">
              {{ judgments.labels.great.toUpperCase() }}
            </th>
            <td>
              <ZeroPaddedNumber :digits="judgmentDigits" :number="score.tapNoteScores.great"/>
            </td>
          </tr>
          <tr>
            <th :style="{color: judgments.colors.decent}" class="align-right">
              {{ judgments.labels.decent.toUpperCase() }}
            </th>
            <td>
              <ZeroPaddedNumber :digits="judgmentDigits" :number="score.tapNoteScores.decent"/>
            </td>
          </tr>
          <tr v-if="score.gameMode != 'FA+'">
            <th :style="{color: judgments.colors.wayOff}" class="align-right">
              {{ judgments.labels.wayOff.toUpperCase() }}
            </th>
            <td>
              <ZeroPaddedNumber :digits="judgmentDigits" :number="score.tapNoteScores.wayOff"/>
            </td>
          </tr>
          <tr>
            <th :style="{color: judgments.colors.miss}" class="align-right">
              {{ judgments.labels.miss.toUpperCase() }}
            </th>
            <td>
              <ZeroPaddedNumber :digits="judgmentDigits" :number="score.tapNoteScores.miss"/>
            </td>
          </tr>
        </table>
      </div>
      <div class="modifiers">
        {{ score.modifiers }}
      </div>
    </div>
    <div class="right">
      <button @click.prevent="remove" :disabled="removing">
        <font-awesome-icon v-if="removing" icon="spinner" pulse/>
        <font-awesome-icon v-else icon="trash-alt"/>
      </button>
    </div>
  </div>
</template>

<script>
import judgments from '@/utils/judgments';
import FormatMixin from '@/utils/FormatMixin';
import ZeroPaddedNumber from '@/components/ZeroPaddedNumber';

export default {
  mixins: [FormatMixin],
  components: {
    ZeroPaddedNumber
  },
  props: {
    score: Object
  },
  data() {
    return {
      rerating: false,
      reratingSubmitting: false,
      newMeter: null,
      removing: false
    };
  },
  created() {
    this.judgments = judgments;
  },
  computed: {
    translit() {
      return this.$store.state.settings.translit;
    },
    difficultyColor() {
      const colors = ['#FF003C', '#C1006F', '#8200A1', '#413AD0', '#0073FF', '#B4B7BA'];
      const difficulties = ['Beginner', 'Easy', 'Medium', 'Hard', 'Challenge', 'Edit'];
      return colors[difficulties.indexOf(this.score.difficulty)];
    },
    fullTitle() {
      if (this.score.song) {
        const song = this.score.song;
        const title = this.translit ? song.translit_title : song.title;
        const subtitle = this.translit ? song.translit_subtitle : song.subtitle;

        var full_title = title;
        if (subtitle)
          full_title += ' ' + subtitle;

        return full_title;
      } else {
        const course = this.score.course;
        return this.translit ? course.translit_full_title : course.full_title;
      }
    },
    judgmentDigits() {
      const radarPossible = this.score.radarPossible;
      const tns = this.score.tapNoteScores;

      return Math.max(
        radarPossible.notes.toString().length,
        tns.blueFantastic.toString().length,
        tns.whiteFantastic.toString().length,
        tns.excellent.toString().length,
        tns.great.toString().length,
        tns.decent.toString().length,
        tns.wayOff.toString().length,
        tns.miss.toString().length,
        4
      );
    },
    otherDigits() {
      const radarPossible = this.score.radarPossible;
      const radarActual = this.score.radarActual;

      return Math.max(
        radarPossible.holds.toString().length,
        radarPossible.mines.toString().length,
        radarPossible.hands.toString().length,
        radarPossible.rolls.toString().length,
        radarActual.holds.toString().length,
        radarActual.mines.toString().length,
        radarActual.hands.toString().length,
        radarActual.rolls.toString().length,
        3
      );
    }
  },
  methods: {
    rerate() {
      this.rerating = true;
      this.newMeter = this.score.meter;

      this.$nextTick(() => {
        this.$refs.meter.focus();
      });
    },
    cancelRerate() {
      this.rerating = false;
    },
    submitRerate() {
      this.rerating = false;
      this.reratingSubmitting = true;

      var data = {'meter': this.newMeter};
      if (this.score.steps) {
        data.steps = this.score.steps.hash;
      } else {
        data.trail = this.score.trail.hash;
      }

      this.$api.post('/re-rate', data).then(() => {
        this.reratingSubmitting = false;
        this.score.meter = this.newMeter;
        this.$emit('score-rerated', this.score);
      }).catch(error => {
        this.reratingSubmitting = false;
        if (error.response && error.response.status == 403) {
          error = error.response.data.error;
        } else {
          error = error.message;
        }
        alert(error);
      });
    },
    remove() {
      if (confirm('Do you want to remove this score?')) {
        this.removing = true;

        this.$api.delete('/scores/' + this.score.guid).then(() => {
          this.removing = false;
          this.$emit('score-removed', this.score);
        }).catch(error => {
          this.removing = false;
          if (error.response && error.response.status == 403) {
            error = error.response.data.error;
          } else {
            error = error.message;
          }
          alert(error);
        });
      }
    }
  }
}
</script>

<style scoped>
.score-details {
  position: relative;
}

.left {
  position: absolute;
  left: 0;
  top: 0;
}

.meter {
  color: black;
  font-family: Wendy;
  font-size: 250%;
  padding: 0.1em 0.2em 0.1em 0.3em;
  display: inline-block;
  vertical-align: middle;
}

.meter > input {
  margin: 0;
  padding: 0;
  border: none;
  width: 1.5em;
  font-family: inherit;
  font-size: inherit;
  background: none;
  outline: none;
  color: black;
}

.rerate {
  margin-top: 0.1em;
  font-size: 80%;
}

.rerate > a {
  color: white;
  text-decoration: none;
}

.chart-info {
  font-family: "Miso Bold";
  padding-left: 0.5em;
  display: inline-block;
  vertical-align: middle;
}

.centered {
  width: 50%;
  margin: 0 auto;
  padding: 0.5em;
  background-color: #1e282f;
  text-align: center;
  margin-bottom: 0.5em;
}

.centered > .title {
  font-family: "Miso Bold";
  font-size: 120%;
  margin-bottom: 0.1em;
}

.centered > .pack {
  margin-bottom: 0.3em;
}

.right {
  position: absolute;
  right: 0;
  top: 0;
}

.score-info {
  background-color: #1e282f;
  display: inline-block;
}

.note-scores {
  display: inline-flex;
  flex-direction: row;
}

.note-scores th {
  padding-left: 1rem;
}

.note-scores td {
  padding-right: 1rem;
}

.note-scores > .column {
  flex: 1;
}

.percent-dp {
  background-color: #101519;
  font-family: Wendy;
  font-size: 350%;
  text-align: right;
}

.slash {
  color: #444444;
  font-size: 150%;
}

.align-right {
  text-align: right;
}

.modifiers {
  font-family: "Miso Bold";
  padding: 0.5em 1em;
}

.nowrap {
  white-space: nowrap;
}

.actions {
  margin-top: 0.5em;
}

@media (max-width: 960px) {
  .centered {
    width: 90%;
    display: block;
  }
  .left {
    position: relative;
    margin-top: 0.5em;
  }
  .right {
    position: relative;
    margin-top: 0.5em;
  }
}
</style>
