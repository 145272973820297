export default {
  keys: [
    'blueFantastic',
    'whiteFantastic',
    'excellent',
    'great',
    'decent',
    'wayOff',
    'miss'
  ],
  colors: {
    'blueFantastic': '#21cce8',
    'whiteFantastic': '#ffffff',
    'excellent': '#e29c18',
    'great': '#66c955',
    'decent': '#b45cff',
    'wayOff': '#c9855e',
    'miss': '#ff3030',
  },
  labels: {
    'blueFantastic': 'Fantastic',
    'whiteFantastic': 'Fantastic',
    'excellent': 'Excellent',
    'great': 'Great',
    'decent': 'Decent',
    'wayOff': 'Way Off',
    'miss': 'Miss',
  }
}
