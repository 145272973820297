<template>
  <div class="diary">
    <div class="entry-list">
      <DiaryMonthSelector @month-selected="selectedMonth = $event"/>
      <DiaryDaySelector
        :month="selectedMonth"
        @date-selected="selectedDate = $event"
      />
    </div>

    <div class="entry-container">
      <DiaryEntry :date="selectedDate"/>
    </div>
  </div>
</template>

<script>
import DiaryMonthSelector from '@/components/DiaryMonthSelector.vue';
import DiaryDaySelector from '@/components/DiaryDaySelector.vue';
import DiaryEntry from '@/components/DiaryEntry.vue';

export default {
  components: {
    DiaryMonthSelector,
    DiaryDaySelector,
    DiaryEntry
  },
  data() {
    return {
      selectedMonth: null,
      selectedDate: null
    }
  }
}
</script>

<style scoped>
.diary {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.entry-list {
  flex: 0 0 250px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}

.entry-container {
  flex: 1 auto;
  padding: 0 1em 1em 1em;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}

@media (max-width: 960px) {
  .entry-container {
    padding: 0 0.5em 0.5em 0.5em;
  }
}

.entry-list::-webkit-scrollbar,
.entry-container::-webkit-scrollbar {
  width: 0.3em;
}
.entry-list::-webkit-scrollbar-thumb,
.entry-container::-webkit-scrollbar-thumb {
  background: #555
}
.entry-list::-webkit-scrollbar-track,
.entry-container::-webkit-scrollbar-track {
  background: transparent;
}

@media (max-width: 1200px) {
  .diary {
    display: block;
  }
}
</style>
