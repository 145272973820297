<template>
  <div class="diary-entry">
    <h2 v-if="date">{{ date | date({timeZone: 'UTC'}) }}</h2>
    <div v-else class="empty">
      <em>Nothing to see yet! Go upload some scores!</em>
    </div>

    <font-awesome-icon v-if="loading" icon="spinner" pulse/>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <div v-else-if="date">
      <label>
        <input type="checkbox" v-model="showFailed">
        Show failed scores
      </label>
      <ScoreTable :scores="scores" :showDate="false"/>
    </div>
  </div>
</template>

<script>
import ScoreTable from '@/components/ScoreTable.vue';
import FormatMixin from '@/utils/FormatMixin';

export default {
  mixins: [FormatMixin],
  components: {
    ScoreTable
  },
  props: {
    date: String
  },
  data() {
    return {
      showFailed: false,
      scores: [],
      loading: false,
      error: null
    };
  },
  watch: {
    date() {
      this.loadData();
    },
    showFailed() {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      const form = {
        fromDate: this.date,
        toDate: this.date
      };

      if (!this.showFailed)
        form['minGrade'] = 'Tier17';

      this.loading = true;
      this.$api.post('/scores', form).then(response => {
        this.scores = response.data.scores;
        this.loading = false;
      }).catch(error => {
        this.error = error.message;
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
.empty {
  margin: 1em;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
}
</style>
