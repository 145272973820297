import Axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBalanceScale,
  faBars,
  faBroom,
  faCandyCane,
  faChartLine,
  faCheck,
  faClock,
  faEdit,
  faGhost,
  faHeart,
  faInfo,
  faInfoCircle,
  faPowerOff,
  faRibbon,
  faShoePrints,
  faSlidersH,
  faSpider,
  faSpinner,
  faTimes,
  faTrashAlt,
  faTree,
  faVenus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faBalanceScale);
library.add(faBars);
library.add(faBroom);
library.add(faCandyCane);
library.add(faChartLine);
library.add(faCheck);
library.add(faClock);
library.add(faEdit);
library.add(faGhost);
library.add(faHeart);
library.add(faInfo);
library.add(faInfoCircle);
library.add(faPowerOff);
library.add(faRibbon);
library.add(faShoePrints);
library.add(faSlidersH);
library.add(faSpider);
library.add(faSpinner);
library.add(faTimes);
library.add(faTrashAlt);
library.add(faTree);
library.add(faVenus);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

const api = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  timeout: 60000      // 60 seconds
});

api.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status == 401) {
    store.commit('setLoggedIn', false);
    store.commit('clearSettings');
  }

  return Promise.reject(error);
});

Vue.prototype.$api = api;

api.get('/settings').then(response => {
  store.commit('setLoggedIn', true);
  store.commit('setSettings', response.data);
}).catch(() => {
  return;
}).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});
