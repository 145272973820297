<template>
  <div class="first-clears">
    <font-awesome-icon v-if="loading" icon="spinner" pulse/>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <table v-else>
      <thead>
        <tr>
          <th>Difficulty</th>
          <th>Date</th>
          <th>Song</th>
          <th class="wide">Pack</th>
          <th class="wide">Grade</th>
          <th class="wide">%</th>
          <th class="narrow">Grade<br>%</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="score in visibleScores"
          :key="score.guid"
        >
          <td class="align-center">
            {{ score.meter }}
            <br>
            <small>{{ score.difficulty | translateDifficulty }}</small>
            <br>
            <small>{{ score.stepsType | translateStepsType }}</small>
          </td>
          <td>{{ score.datetime | date({year: 'numeric'}) }}</td>
          <template v-if="score.song">
            <td>
              {{ translit ? score.song.translit_title : score.song.title }}
              <template v-if="score.song.subtitle">
                <br>
                <small>
                  {{ translit ? score.song.translit_subtitle : score.song.subtitle }}
                </small>
              </template>
            </td>
            <td class="wide">
              {{ score.song.pack }}
            </td>
          </template>
          <template v-else-if="score.course">
            <td>
              {{ translit ? score.course.translit_full_title : score.course.full_title }}
            </td>
            <td>
              <em>Marathon</em>
            </td>
          </template>
          <td class="align-center wide">{{ score.grade | translateGrade }}</td>
          <td class="align-right wide">{{ (score.percentDP * 100).toFixed(2) }}</td>
          <td class="align-center narrow">
            {{ score.grade | translateGrade }}<br>
            {{ (score.percentDP * 100).toFixed(2) }}
          </td>
        </tr>
        <tr
          v-if="!expanded && scores.length > 5"
          @click="expanded = true"
        >
          <td colspan="6" class="show-all align-center">
            Show All
          </td>
        </tr>
        <tr v-else-if="!scores.length">
          <td colspan="6" class="align-center">
            <em>Nothing to see yet! Go upload some scores!</em>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import FormatMixin from '@/utils/FormatMixin';

export default {
  mixins: [FormatMixin],
  created() {
    this.loadData();
  },
  data() {
    return {
      scores: [],
      expanded: false,
      loading: false,
      error: null
    };
  },
  computed: {
    visibleScores() {
      if (this.expanded) {
        return this.scores;
      } else {
        return this.scores.slice(0, 5);
      }
    },
    translit() {
      return this.$store.state.settings.translit;
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      this.$api.get('/first-clears').then(response => {
        this.scores = response.data.firstClears.reverse();
        this.error = null;
        this.loading = false;
      }).catch(error => {
        this.error = error.message;
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
  border-spacing: 0;
  line-height: 85%;
}

thead {
  color: white;
}

thead > tr > th {
  white-space: nowrap;
  border-bottom: 2px solid black;
  background-color: #1e282f;
  padding: 0.2em 0.5em;
}

tbody > tr {
  background-color: #1e282f;
}

tbody > tr:nth-child(odd) {
  background-color: #182025;
}

tbody > tr.clickable:hover {
  background-color: #4c565d;
  cursor: pointer;
}

tbody > tr.details {
  background-color: transparent;
}

tbody > tr > td {
  padding: 0.5em 0.5em;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.show-all {
  cursor: pointer;
}

.show-all:hover {
  background-color: #4c565d;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
}

.narrow {
  display: none;
}

@media (max-width: 960px) {
  .narrow {
    display: table-cell;
  }
  .wide {
    display: none;
  }
}
</style>
