<template>
  <div class="meter-chart">
    <font-awesome-icon v-if="loading" icon="spinner" pulse/>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <div v-else class="chart" ref="chart"></div>

    <div ref="chartLineIcon" class="hidden">
      <font-awesome-icon icon="chart-line"/>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import FormatMixin from '@/utils/FormatMixin';

export default {
  mixins: [FormatMixin],
  props: {
    filters: {
      type: Object,
      default() {
        return {
          minGrade: 'Tier17',
          maxMeter: 30
        };
      }
    }
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      points: null,
      loading: false,
      error: null
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    filters() {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      this.$api.post('/stats/meter', this.filters || {}).then(response => {
        this.fromDate = response.data.fromDate;
        this.toDate = response.data.toDate;
        this.resolution = response.data.resolution;
        this.points = response.data.points;
        this.loading = false;

        this.$nextTick(this.initChart);
      }).catch(error => {
        this.error = error.message;
        this.loading = false;
      });
    },
    initChart() {
      const stepChart = echarts.init(this.$refs.chart);

      stepChart.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#182025',
          formatter: params => {
            const fromDate = params[0].value.fromDate;
            const toDate = params[0].value.toDate;
            const minMeter = params[0].value.minMeter;
            const maxMeter = params[0].value.maxMeter;
            const medianMeter = params[0].value.medianMeter;
            var displayDate = null;

            if (this.resolution == 'day') {
              displayDate = this.formatDate(fromDate, {year: 'numeric'});
            } else if (this.resolution == 'week') {
              displayDate = this.formatDate(fromDate) + ' &ndash; ' + this.formatDate(toDate, {year: 'numeric'});
            } else if (this.resolution == 'month') {
              displayDate = this.formatMonth(fromDate);
            }

            const div = document.createElement('div');
            div.style.textAlign = 'center';
            div.innerHTML =
              displayDate +
              '<br>' + this.$refs.chartLineIcon.innerHTML +
              ' ' + minMeter + ' &ndash; ' + maxMeter +
              ' (median: ' + medianMeter + ')';

            return div.outerHTML;
          },
          textStyle: {
            fontFamily: 'Miso',
            fontSize: 18
          }
        },
        grid: {
          containLabel: true,
          left: 20,
          right: 20,
          top: 20,
          bottom: 0
        },
        xAxis: {
          type: 'time',
          min: (this.filters || {}).fromDate || 'dataMin',
          max: (this.filters || {}).toDate || 'dataMax',
          splitNumber: Math.floor(this.$refs.chart.clientWidth / 100),
          axisLine: {
            lineStyle: {
              color: 'white'
            }
          },
          axisLabel: {
            fontFamily: 'Miso',
            fontSize: 18,
            formatter: val => {
              return this.formatDate(val, {year: 'numeric'});
            }
          },
          splitLine: {
            lineStyle: {
              color: '#182025'
            }
          }
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 30,
          axisLine: {
            lineStyle: {
              color: 'white'
            }
          },
          axisLabel: {
            fontFamily: 'Miso',
            fontSize: 18
          },
          splitLine: {
            lineStyle: {
              color: '#182025'
            }
          }
        },
        dataset: {
          source: this.points
        },
        series: [
          {
            type: 'line',
            stack: 'stack',
            encode: {
              x: 'fromDate',
              y: 'minMeter',
            },
            animation: false,
            color: '#4c565d',
            showSymbol: false
          },
          {
            type: 'line',
            areaStyle: {
              opacity: 1
            },
            stack: 'stack',
            encode: {
              x: 'fromDate',
              y: 'difference',
            },
            animation: false,
            color: '#4c565d',
            showSymbol: false
          },
          {
            type: 'line',
            encode: {
              x: 'fromDate',
              y: 'medianMeter',
            },
            animation: false,
            color: 'white'
          }
        ]
      });
    },
    formatDate(date, options) {
      return this.$options.filters.date(date, {timeZone: 'UTC', ...options});
    },
    formatMonth(date) {
      return new Date(date).toLocaleString('en-US', {month: 'short', year: 'numeric', timeZone: 'UTC'});
    }
  }
}
</script>

<style scoped>
.meter-chart {
  background-color: #1e282f;
  padding: 1em;
}

.chart {
  width: 100%;
  height: 400px;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
}

.hidden {
  display: none;
}

@media (max-width: 960px) {
  .chart {
    height: 250px;
  }
}
</style>
