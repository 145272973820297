<template>
  <div class="dashboard">
    <div class="column">
      <h2>First Clears by Difficulty</h2>
      <FirstClearsTable/>

      <h2>Difficulty Passed</h2>
      <MeterChart/>
    </div>

    <div class="column">
      <h2>Steps per Day</h2>
      <StepsChart/>

      <h2>Timing</h2>
      <TimingChart/>
    </div>
  </div>
</template>

<script>
import FirstClearsTable from '@/components/FirstClearsTable.vue';
import MeterChart from '@/components/MeterChart.vue';
import StepsChart from '@/components/StepsChart.vue';
import TimingChart from '@/components/TimingChart.vue';

export default {
  components: {
    FirstClearsTable,
    MeterChart,
    StepsChart,
    TimingChart
  }
}
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: row;
}

.column {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 1em;
}
@media (max-width: 960px) {
  .column {
    padding: 0.5em;
  }
}

h2 {
  text-align: center;
}

@media (max-width: 960px) {
  .dashboard {
    flex-direction: column;
  }
}
</style>
