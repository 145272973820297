import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Charts from '../views/Charts.vue'
import Diary from '../views/Diary.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Settings from '../views/Settings.vue'
import Scores from '../views/Scores.vue'
import Upload from '../views/Upload.vue'
import UploadConverter from '../views/UploadConverter.vue'
import NotFound from '../views/NotFound.vue'
import FAQ from '../views/FAQ.vue'
import Welcome from '../views/Welcome.vue'
import store from '@/store'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/diary',
    name: 'diary',
    component: Diary,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/scores',
    name: 'scores',
    component: Scores,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/charts',
    name: 'charts',
    component: Charts,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/upload',
    name: 'upload',
    component: Upload,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/upload/convert',
    name: 'upload-converter',
    component: UploadConverter,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.loggedIn) {
      next();
    } else {
      next('login');
    }
  } else {
    next();
  }
});

export default router
