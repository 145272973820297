<template>
  <div class="diary-month-selector">
    <font-awesome-icon v-if="loading" icon="spinner" pulse/>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <select
      v-else
      v-model="selected"
      @change="$emit('month-selected', selected)"
    >
      <option
        v-for="month in months"
        :key="month"
        :value="month"
      >
        {{ month | pretty }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      months: [],
      selected: null,
      loading: true,
      error: null
    };
  },
  mounted() {
    this.$api.get('/diary').then(response => {
      this.months = response.data.months.reverse();
      this.selected = this.months[0];
      if (this.selected)
        this.$emit('month-selected', this.selected);
      this.loading = false;
    }).catch(error => {
      this.error = error.message;
      this.loading = false;
    });
  },
  filters: {
    pretty(month) {
      const d = new Date(month);
      return d.toLocaleString('en-US', {year: 'numeric', month: 'long', timeZone: 'UTC'});
    }
  }
}
</script>

<style scoped>
select {
  width: 100%;
  font-weight: bold;
  font-size: 90%;
  text-align: center;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
}
</style>
