<template>
  <div class="upload-converter">
    <h1>Upload Converter</h1>

    <form @submit.prevent="submit">
      <div class="info">
        The converter can be used for one-off conversion and upload of
        existing StepMania score files.
        <br>
        Learn more in the
        <router-link to="/faq">FAQ</router-link>.
      </div>

      <label>
        Save/Upload/
        <input
          type="file"
          accept=".xml,application/xml,text/xml"
          webkitdirectory
          multiple
          :disabled="loading"
          @change="uploadFiles = $event.target.files"
        />
      </label>

      <label>
        Cache/Songs/
        <input
          type="file"
          webkitdirectory
          multiple
          :disabled="loading"
          @change="setCacheFiles($event.target.files)"
        />
      </label>

      <label>
        Player GUID
        <input
          type="text"
          v-model="playerGuid"
          placeholder="All players"
          :disabled="loading"
        />
      </label>

      <p>
        You can find the player GUID in your profile's <em>Stats.xml</em> file.
        It's the alphanumeric string between <tt>&lt;Guid&gt;</tt> and
        <tt>&lt;/Guid&gt;</tt>. Leave the input empty to import all scores.
      </p>

      <template v-if="uploadFiles && songCacheFiles">
        <div v-if="uploadDone" class="done">
          <font-awesome-icon icon="check"/>
          Upload complete!
        </div>
        <progress
          v-else-if="loading"
          :value="parseIdx"
          :max="uploadFiles.length"
        >
          {{ parseIdx }}/{{ uploadFiles.length }}
        </progress>
        <div v-if="loading" class="stats">
          <div v-if="stats.otherPlayer">
            <font-awesome-icon icon="info"/>
            Skipped {{ stats.otherPlayer }} score(s) of other players
          </div>
          <div v-if="stats.coursesSkipped">
            <font-awesome-icon icon="info"/>
            Skipped {{ stats.coursesSkipped }} marathon score(s)
          </div>
          <div v-if="stats.uploadedSuccessfully">
            <font-awesome-icon icon="check"/>
            Uploaded {{ stats.uploadedSuccessfully }} score(s) successfully
          </div>
          <div v-if="stats.notInCache">
            <span class="error"><font-awesome-icon icon="times"/></span>
            Skipped {{ stats.notInCache }} score(s), due to the song not being in the cache
            <a v-if="!expanded" @click.prevent="expanded = true">(Show errors)</a>
          </div>
          <div v-if="stats.parseFail">
            <span class="error"><font-awesome-icon icon="times"/></span>
            Failed to parse {{ stats.parseFail }} score(s)
            <a v-if="!expanded" @click.prevent="expanded = true">(Show errors)</a>
          </div>
          <div v-if="stats.uploadFailed">
            <span class="error"><font-awesome-icon icon="times"/></span>
            Failed to upload {{ stats.uploadFailed }} score(s)
            <a v-if="!expanded" @click.prevent="expanded = true">(Show errors)</a>
          </div>
        </div>
        <button v-else>Upload</button>
      </template>
    </form>
    <div v-if="expanded" class="errors">{{ errors }}</div>
  </div>
</template>

<script>
import FormatMixin from '@/utils/FormatMixin';

export default {
  mixins: [FormatMixin],
  data() {
    return {
      uploadFiles: null,
      songCacheFiles: null,
      playerGuid: null,
      loading: false,
      parseIdx: null,
      errors: '',
      uploadDone: false,
      cache: {},
      stats: {
        coursesSkipped: 0,
        otherPlayer: 0,
        uploadedSuccessfully: 0,
        notInCache: 0,
        uploadFailed: 0,
        parseFail: 0
      },
      expanded: false
    }
  },
  methods: {
    setCacheFiles(files) {
      this.songCacheFiles = {};
      for (const file of files)
        this.songCacheFiles[file.name] = file;
    },
    async readFile(file) {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
        reader.onerror = (ev) => {
          reject(ev.target.error);
        };
      });

      return await promise;
    },
    detectGameMode(score, steps) {
      const weights = {
        ITG: {
          w1: 5,
          w2: 4,
          w3: 2,
          w4: 0,
          w5: -6,
          miss: -12,
          letGo: 0,
          held: 5,
          hitMine: -6
        },
        'FA+': {
          w1: 5,
          w2: 5,
          w3: 4,
          w4: 2,
          w5: 0,
          miss: -12,
          letGo: 0,
          held: 5,
          hitMine: -6
        }
      };

      const maxGradePoints = (
        steps.Radar.TapsAndHolds +
        steps.Radar.Holds +
        steps.Radar.Rolls
      ) * 5;

      const minPoints = Math.floor(maxGradePoints * score.PercentDP);
      const maxPoints = Math.ceil(maxGradePoints * (score.PercentDP + 0.0001));

      for (const gameMode of ['ITG', 'FA+']) {
        const params = weights[gameMode];

        var gradePoints = (
          score.TapNoteScores.W1 * params.w1 +
          score.TapNoteScores.W2 * params.w2 +
          score.TapNoteScores.W3 * params.w3 +
          score.TapNoteScores.W4 * params.w4 +
          score.TapNoteScores.W5 * params.w5 +
          score.TapNoteScores.Miss * params.miss +
          score.HoldNoteScores.LetGo * params.letGo +
          score.HoldNoteScores.Held * params.held +
          score.TapNoteScores.HitMine * params.hitMine
        );
        if (gradePoints < 0)
          gradePoints = 0;

        if (minPoints <= gradePoints && gradePoints < maxPoints)
          return gameMode;
      }

      return 'ITG';
    },
    async getCacheFile(songDir) {
      const filename = songDir.replace(/\/$/, '').replaceAll('/', '_');

      const file = this.songCacheFiles[filename];
      if (file)
          return await this.readFile(file);

      throw 'song not found in cache';
    },
    async getCacheData(songDir, stepsType, difficulty) {
      var song = this.cache[songDir];
      if (!song) {
        const text = await this.getCacheFile(songDir);

        song = {
          Dir: '/' + songDir,
          Group: songDir.split('/').slice(-3)[0],
          RandomBPM: false,
          steps: {}
        };
        var currentSteps = null;

        for (const match of text.matchAll(/^#(\w+):((?:[^;\\]|\\.)*);/gm)) {
          const key = match[1].toLowerCase();
          const value = match[2].replaceAll(/\\(.)/g, '$1');

          switch (key) {
            case 'title':
              song.Title = value;
              break;
            case 'subtitle':
              if (value)
                song.SubTitle = value;
              break;
            case 'artist':
              if (value)
                song.Artist = value;
              break;
            case 'titletranslit':
              if (value)
                song.TranslitTitle = value;
              break;
            case 'subtitletranslit':
              if (value)
                song.TranslitSubTitle = value;
              break;
            case 'artisttranslit':
              if (value)
                song.TranslitArtist = value;
              break;
            case 'genre':
              if (value)
                song.Genre = value;
              break;
            case 'displaybpm':
              if (value == '*') {
                song.RandomBPM = true;
              } else {
                const parts = value.split(':');
                var low, high;

                if (parts.length > 1) {
                  low = Math.round(parseFloat(parts[0]));
                  high = Math.round(parseFloat(parts[1]));
                  if (low > high)
                    [low, high] = [high, low];
                } else {
                  const bpm = Math.round(parseFloat(value));
                  low = bpm;
                  high = bpm;
                }

                if (low > 0 && high > 0)
                  song.BPM = [low, high];
              }
              break;
            case 'bpms': {
              const bpms = [];
              for (const pair of value.split(',')) {
                const bpm = Math.round(parseFloat(pair.split('=')[1]));
                bpms.push(bpm);
              }

              if (!song.BPM) {
                const low = Math.min.apply(null, bpms);
                const high = Math.max.apply(null, bpms);
                song.BPM = [low, high];
              }
              break;
            }
            case 'musiclength':
              song.MusicLengthSeconds = Math.round(parseFloat(value));
              break;
            case 'notedata':
              if (currentSteps != null)
                song.steps[[currentSteps.StepsType, currentSteps.Difficulty]] = currentSteps;

              currentSteps = {};
              break;
            case 'difficulty':
              console.assert(currentSteps != null);
              currentSteps.Difficulty = value;
              break;
            case 'meter':
              console.assert(currentSteps != null);
              currentSteps.Meter = parseInt(value, 10);
              break;
            case 'stepstype':
              console.assert(currentSteps != null);
              currentSteps.StepsType = value;
              break;
            case 'credit':
              if (currentSteps != null)
                currentSteps.AuthorCredit = value;
              break;
            case 'description':
              console.assert(currentSteps != null);
              currentSteps.Description = value;
              break;
            case 'radarvalues': {
              console.assert(currentSteps != null);
              const vals = value.split(',').map(v => Math.round(parseFloat(v)));
              console.assert(vals.length % 14 == 0);

              currentSteps.Radar = {
                Notes: vals[5],
                TapsAndHolds: vals[6],
                Jumps: vals[7],
                Holds: vals[8],
                Mines: vals[9],
                Hands: vals[10],
                Rolls: vals[11]
              };
              break;
            }
          }

          if (currentSteps != null)
            song.steps[[currentSteps.StepsType, currentSteps.Difficulty]] = currentSteps;

          if (!song.TranslitTitle)
            song.TranslitTitle = song.Title;
          if (!song.TranslitSubTitle && song.SubTitle)
            song.TranslitSubTitle = song.SubTitle;
          if (!song.TranslitArtist && song.Artist)
            song.TranslitArtist = song.Artist;
        }

        this.cache[songDir] = song;
      }

      const stepsInfo = song.steps[[stepsType, difficulty]];
      if (!stepsInfo)
        throw 'steps not found';

      const songInfo = {...song};
      delete songInfo.steps;

      return {
        song: songInfo,
        steps: stepsInfo
      };
    },
    parseScore(highScore) {
      const tns = highScore.querySelector('TapNoteScores');
      const hns = highScore.querySelector('HoldNoteScores');
      const radar = highScore.querySelector('RadarValues');

      const missedHold = hns.querySelector('MissedHold');

      const tapsAndHolds = parseInt(radar.querySelector('TapsAndHolds').textContent, 10);
      const jumps = parseInt(radar.querySelector('Jumps').textContent, 10);
      var notes = radar.querySelector('Notes');
      if (notes) {
        notes = parseInt(notes.textContent, 10);
      } else {
        notes = tapsAndHolds + jumps;
      }

      return {
        Guid: highScore.querySelector('Guid').textContent,
        Grade: highScore.querySelector('Grade').textContent,
        Score: parseInt(highScore.querySelector('Score').textContent, 10),
        PercentDP: parseFloat(highScore.querySelector('PercentDP').textContent),
        SurviveSeconds: parseFloat(highScore.querySelector('SurviveSeconds').textContent),
        MaxCombo: parseInt(highScore.querySelector('MaxCombo').textContent, 10),
        Modifiers: highScore.querySelector('Modifiers').textContent,
        DateTime: highScore.querySelector('DateTime').textContent,
        PlayerGuid: highScore.querySelector('PlayerGuid').textContent || highScore.querySelector('MachineGuid').textContent,
        Disqualified: parseInt(highScore.querySelector('Disqualified').textContent, 10) > 0,
        TapNoteScores: {
          W1: parseInt(tns.querySelector('W1').textContent, 10),
          W2: parseInt(tns.querySelector('W2').textContent, 10),
          W3: parseInt(tns.querySelector('W3').textContent, 10),
          W4: parseInt(tns.querySelector('W4').textContent, 10),
          W5: parseInt(tns.querySelector('W5').textContent, 10),
          Miss: parseInt(tns.querySelector('Miss').textContent, 10),
          HitMine: parseInt(tns.querySelector('HitMine').textContent, 10),
          AvoidMine: parseInt(tns.querySelector('AvoidMine').textContent, 10),
          CheckpointMiss: parseInt(tns.querySelector('CheckpointMiss').textContent, 10),
          CheckpointHit: parseInt(tns.querySelector('CheckpointHit').textContent, 10)
        },
        HoldNoteScores: {
          LetGo: parseInt(hns.querySelector('LetGo').textContent, 10),
          Held: parseInt(hns.querySelector('Held').textContent, 10),
          MissedHold: missedHold ? parseInt(missedHold.textContent, 10) : 0
        },
        Radar: {
          Notes: notes,
          TapsAndHolds: tapsAndHolds,
          Jumps: jumps,
          Holds: parseInt(radar.querySelector('Holds').textContent, 10),
          Mines: parseInt(radar.querySelector('Mines').textContent, 10),
          Hands: parseInt(radar.querySelector('Hands').textContent, 10),
          Rolls: parseInt(radar.querySelector('Rolls').textContent, 10),
        }
      };
    },
    async convertFile(file) {
      const text = await this.readFile(file);
      const parser = new DOMParser();
      const root = parser.parseFromString(text, 'application/xml');

      const template = {
        Version: 1,
        Theme: 'simply.training online converter',
        ThemeVersion: '1.1',
        ProductID: 'simply.training online converter',
        ProductVersion: '1.1',
        MachineGuid: root.querySelector('MachineGuid').textContent
      };
      const converted = [];

      for (const element of root.querySelectorAll('HighScoreForASongAndSteps')) {
        const playerGuid = element.querySelector('HighScore > PlayerGuid').textContent;
        if (this.playerGuid && playerGuid != this.playerGuid) {
          this.stats.otherPlayer++;
          continue;
        }

        const songDir = element.querySelector('Song').getAttribute('Dir');
        const stepsType = element.querySelector('Steps').getAttribute('StepsType');
        const difficulty = element.querySelector('Steps').getAttribute('Difficulty');

        var cacheInfo;
        try {
          cacheInfo = await this.getCacheData(songDir, stepsType, difficulty);
        } catch (error) {
          this.errors += file.name + ': ' + songDir + ' not found in cache, skipped\n';
          this.stats.notInCache++;
          continue;
        }

        const data = {...template};
        data.Score = this.parseScore(element.querySelector('HighScore'));
        data.Song = cacheInfo.song;
        data.Steps = cacheInfo.steps;
        data.GameMode = this.detectGameMode(data.Score, data.Steps);

        converted.push(data);
      }

      for (const element of root.querySelectorAll('HighScoreForACourseAndTrail')) {
        const playerGuid = element.querySelector('HighScore > PlayerGuid').textContent;
        if (this.playerGuid && playerGuid != this.playerGuid) {
          this.stats.otherPlayer++;
          continue;
        }

        this.stats.coursesSkipped++;
      }

      return converted;
    },
    async uploadScore(data, filename) {
      try {
        await this.$api.post('/upload', data);
        this.stats.uploadedSuccessfully++;
      } catch (error) {
        var message;
        if (error.response && error.response.status == 403) {
          message = error.response.data.error;
        } else {
          message = error.message;
        }

        this.errors += filename + ': upload failed: ' + message + '\n';
        this.stats.uploadFailed++;
      }
    },
    async submit() {
      this.loading = true;

      for (this.parseIdx = 0; this.parseIdx < this.uploadFiles.length; this.parseIdx++) {
        const file = this.uploadFiles[this.parseIdx]

        var scores;
        try {
          scores = await this.convertFile(file);
        } catch (error) {
          this.errors += file.name + ': parsing failed: ' + error + '\n';
          this.stats.parseFail++;
          continue;
        }

        for (const score of scores)
          await this.uploadScore(score, file.name);
      }

      this.uploadDone = true;
    },
  }
}
</script>

<style scoped>
.upload-converter {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 1em auto;
  padding: 0 1em;
  box-sizing: border-box;
  background-color: #1e282f;
}

h1 {
  text-align: center;
}

.info {
  text-align: center;
  margin-bottom: 1em;
}

input, button {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1em;
}

progress {
  appearance: none;
  margin-bottom: 1em;
  width: 100%;
  color: #8200a1;
  background-color: #182025;
  border: 1px solid #444444;
}
progress::-webkit-progress-bar {
  background-color: #8200a1;
}
progress::-moz-progress-bar {
  background-color: #8200a1;
}

.done {
  margin-bottom: 1em;
  text-align: center;
  font-family: 'Wendy';
  font-size: 120%;
}

.stats {
  margin-bottom: 1em;
}

a {
  color: #dd57ff;
  text-decoration: none;
  cursor: pointer;
}

.error {
  color: #ff3030;
}

.errors {
  color: #ff3030;
  font-family: "Miso Bold";
  white-space: pre-wrap;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 1em;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}
.errors::-webkit-scrollbar {
  width: 0.3em;
}
.errors:-webkit-scrollbar-thumb {
  background: #555
}
.errors::-webkit-scrollbar-track {
  background: transparent;
}
</style>
