<template>
  <div class="register">
    <h1>Register</h1>

    <form @submit.prevent="submit">
      <label>
        Name
        <input
          v-model="username"
          type="text"
          required
          minlength="3"
          ref="username"
          autocapitalize="off"
          autocorrect="off"
          spellcheck="false"
        >
      </label>
      <label>
        Password
        <input
          v-model="password"
          type="password"
          required
          minlength="8"
        >
      </label>
      <label>
        Password (again)
        <input
          v-model="passwordAgain"
          type="password"
          required
          minlength="8"
        >
      </label>
      <label>
        Email
        <input
          v-model="email"
          type="email"
          required
        >
      </label>

      <p v-if="error" class="error">
        {{ error }}
      </p>

      <p>
        Your data is not visible to other users of Simply Training, with one
        exception: We plan on releasing statistics in an annual report. It will
        contain data like the number of users, the number of songs played by
        the whole userbase, difficulty levels played and other aggregations,
        completely anonymized.
      </p>

      <p>
        You can delete your account at any time. When you delete your account
        all associated data is deleted too.
      </p>

      <button :disabled="loading || !username || !password || !passwordAgain || !email">
        <font-awesome-icon v-if="loading" icon="spinner" pulse/>
        <template v-else>Register</template>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      passwordAgain: '',
      email: '',
      error: null,
      loading: false
    }
  },
  mounted() {
    this.$refs.username.focus();
  },
  methods: {
    submit() {
      if (this.password != this.passwordAgain) {
        this.error = 'Passwords don\'t match';
        return;
      }

      this.loading = true;

      this.$api.post('/register', {
        username: this.username,
        password: this.password,
        email: this.email
      }).then(response => {
        this.error = null;
        this.loading = false;

        this.$store.commit('setLoggedIn', true);
        this.$store.commit('setSettings', response.data);
        this.$router.push({name: 'welcome'});
      }).catch(error => {
        if (error.response && error.response.status == 422) {
          this.error = error.response.data.error;
        } else {
          this.error = error.message;
        }
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
.register {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 1em auto;
  padding: 0 1em;
  box-sizing: border-box;
  background-color: #1e282f;
}

h1 {
  text-align: center;
}

input, button {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1em;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
}

p:not(.error) {
  font-family: sans-serif;
  font-size: 70%;
  color: #cccccc;
}
</style>
