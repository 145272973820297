<template>
  <div class="charts">
    <form @submit.prevent="loadData">
      <div class="filter">
        <label>Date</label>
        <input
          v-model="form.fromDate"
          type="date"
          required
        >
        &ndash;
        <input
          v-model="form.toDate"
          type="date"
          :min="form.fromDate"
          required
        >
      </div>
      <div class="filter">
        <label>Type</label>
        <select v-model="form.stepsType">
          <option :value="null">All</option>
          <option
            v-for="stepsType in ['dance-single', 'dance-double', 'dance-couple']"
            :key="stepsType"
            :value="stepsType"
          >
            {{ stepsType | translateStepsType }}
          </option>
        </select>
      </div>
      <div class="filter">
        <label>Steps</label>
        <select v-model="form.difficulty">
          <option :value="null">All</option>
          <option
            v-for="difficulty in ['Beginner', 'Easy', 'Medium', 'Hard', 'Challenge', 'Edit']"
            :key="difficulty"
            :value="difficulty"
          >
            {{ difficulty | translateDifficulty }}
          </option>
        </select>
      </div>
      <div class="filter">
        <label>Difficulty</label>
        <input
          v-model.number="form.minMeter"
          type="number"
          min="1"
          max="30"
          class="meter"
          placeholder="min"
        >
        &ndash;
        <input
          v-model.number="form.maxMeter"
          type="number"
          :min="form.minMeter || 1"
          max="30"
          class="meter"
          placeholder="max"
        >
      </div>
      <div class="filter">
        <label>BPM</label>
        <input
          v-model.number="form.minBPM"
          type="number"
          min="0"
          max="1000"
          step="10"
          class="bpm"
          placeholder="min"
        >
        &ndash;
        <input
          v-model.number="form.maxBPM"
          type="number"
          :min="form.minBPM || 0"
          max="1000"
          step="10"
          class="bpm"
          placeholder="max"
        >
      </div>
      <div class="filter">
        <label>Grade</label>
        <select v-model="form.minGrade">
          <option
            v-for="grade in ['Tier01', 'Tier02', 'Tier03', 'Tier04', 'Tier05', 'Tier06', 'Tier07', 'Tier08', 'Tier09', 'Tier10', 'Tier11', 'Tier12', 'Tier13', 'Tier14', 'Tier15', 'Tier16', 'Tier17', 'Failed']"
            :key="grade"
            :value="grade"
          >
            {{ grade | translateGrade }}
          </option>
        </select>
        &ndash;
        <select v-model="form.maxGrade">
          <option
            v-for="grade in ['Tier01', 'Tier02', 'Tier03', 'Tier04', 'Tier05', 'Tier06', 'Tier07', 'Tier08', 'Tier09', 'Tier10', 'Tier11', 'Tier12', 'Tier13', 'Tier14', 'Tier15', 'Tier16', 'Tier17', 'Failed']"
            :key="grade"
            :value="grade"
          >
            {{ grade | translateGrade }}
          </option>
        </select>
      </div>
      <div class="filter">
        <label>%</label>
        <input
          v-model.number="form.minPercentDP"
          type="number"
          min="0"
          max="100"
          step="0.01"
          class="percent-dp"
          placeholder="min"
        >
        &ndash;
        <input
          v-model.number="form.maxPercentDP"
          type="number"
          :min="form.minPercentDP || 0"
          max="100"
          step="0.01"
          class="percent-dp"
          placeholder="max"
        >
      </div>

      <button :disabled="loading">
        <font-awesome-icon v-if="loading" icon="spinner" pulse/>
        <template v-else>Search</template>
      </button>
    </form>

    <div class="chart-select">
      <button
        @click.prevent="chart = 'meter'"
        :class="{active: chart == 'meter'}"
      >
        Difficulty
      </button>
      <button
        @click.prevent="chart = 'steps'"
        :class="{active: chart == 'steps'}"
      >
        Steps
      </button>
      <button
        @click.prevent="chart = 'timing'"
        :class="{active: chart == 'timing'}"
      >
        Timing
      </button>
    </div>

    <MeterChart v-if="chart == 'meter'" :filters="filters"/>
    <StepsChart v-else-if="chart == 'steps'" :filters="filters"/>
    <TimingChart v-else-if="chart == 'timing'" :filters="filters"/>
  </div>
</template>

<script>
import FormatMixin from '@/utils/FormatMixin';
import MeterChart from '@/components/MeterChart.vue';
import StepsChart from '@/components/StepsChart.vue';
import TimingChart from '@/components/TimingChart.vue';

export default {
  mixins: [FormatMixin],
  components: {
    MeterChart,
    StepsChart,
    TimingChart
  },
  created() {
    this.loadData();
  },
  data() {
    const today = new Date();
    const oneMonthAgo = new Date(today.getTime());
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const fromDate = (
      oneMonthAgo.getFullYear() + '-' +
      ('0' + (oneMonthAgo.getMonth() + 1)).slice(-2) + '-' +
      ('0' + oneMonthAgo.getDate()).slice(-2)
    );
    const toDate = (
      today.getFullYear() + '-' +
      ('0' + (today.getMonth() + 1)).slice(-2) + '-' +
      ('0' + today.getDate()).slice(-2)
    );

    return {
      form: {
        fromDate: fromDate,
        toDate: toDate,
        stepsType: null,
        difficulty: null,
        minMeter: null,
        maxMeter: null,
        minBPM: null,
        maxBPM: null,
        minGrade: 'Tier17',
        maxGrade: 'Tier01',
        minPercentDP: null,
        maxPercentDP: null
      },
      chart: 'timing',
      filters: null,
      loading: false
    };
  },
  methods: {
    loadData() {
      const filters = {};

      Object.keys(this.form).forEach(k => {
        if (this.form[k])
          filters[k] = this.form[k];
      });

      this.filters = filters;
    }
  }
}
</script>

<style scoped>
.charts {
  padding: 0 1em 1em 1em;
}
@media (max-width: 960px) {
  .charts {
    padding: 0 0.5em 0.5em 0.5em;
  }
}

form {
  margin: 0.5em;
}

.filter {
  border: 1px solid #444444;
  display: inline-block;
  margin: 0.2em 0.5em 0 0;
  background-color: #1e282f;
  color: white;
  vertical-align: middle;
}

.chart-select {
  text-align: center;
  padding: 0.5em 0;
  background-color: #1e282f;
}

.chart-select > button {
  margin: 0;
  font-size: 90%;
  background-color: #182025;
  border-width: 1px 0 1px 1px;
}

.chart-select > button:last-child {
  border-width: 1px;
}

.chart-select > button:hover {
  background-color: #4c565d;
}

.chart-select > button.active {
  background-color: #8200a1;
}

input, select {
  border-width: 0 1px 0 1px;
}

input:last-child, select:last-child {
  border-width: 0 0 0 1px;
}

label {
  padding: 0.5em;
  font-family: "Miso Bold";
}

input.meter {
  width: 2.5em;
}

input.bpm {
  width: 3em;
}

input.percent-dp {
  width: 3em;
}

button {
  margin: 0.5em 1em 0.5em 1em;
}
</style>
