<template>
  <div class="timing-bar">
    <span
      v-for="w in judgments.keys"
      :key="w"
      :style="{flexGrow: counts[w], backgroundColor: judgments.colors[w]}"
    />
  </div>
</template>

<script>
import judgments from '@/utils/judgments';

export default {
  props: {
    counts: Object
  },
  created() {
    this.judgments = judgments;
  }
}
</script>

<style scoped>
.timing-bar {
    display: flex;
    width: 100%;
    height: 1em;
}
.window {
    flex: 0 0 0;
}
</style>
