<template>
  <div class="timing-chart">
    <font-awesome-icon v-if="loading" icon="spinner" pulse/>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <div v-else class="chart" ref="chart"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import FormatMixin from '@/utils/FormatMixin';
import judgments from '@/utils/judgments';

export default {
  mixins: [FormatMixin],
  props: {
    filters: Object
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      points: null,
      loading: false,
      error: null
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    filters() {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      this.$api.post('/stats/timing', this.filters || {}).then(response => {
        this.fromDate = response.data.fromDate;
        this.toDate = response.data.toDate;
        this.resolution = response.data.resolution;
        this.points = response.data.points;
        this.loading = false;

        this.$nextTick(this.initChart);
      }).catch(error => {
        this.error = error.message;
        this.loading = false;
      });
    },
    initChart() {
      const timingChart = echarts.init(this.$refs.chart);

      timingChart.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#182025',
          formatter: params => {
            const fromDate = params[0].value.fromDate;
            const toDate = params[0].value.toDate;
            var displayDate = null;

            if (this.resolution == 'day') {
              displayDate = this.formatDate(fromDate, {year: 'numeric'});
            } else if (this.resolution == 'week') {
              displayDate = this.formatDate(fromDate) + ' &ndash; ' + this.formatDate(toDate, {year: 'numeric'});
            } else if (this.resolution == 'month') {
              displayDate = this.formatMonth(fromDate);
            }

            const header = document.createElement('span');
            header.style.width = '100%';
            header.style.textAlign = 'center';
            header.style.display = 'inline-block';
            header.innerHTML = displayDate;

            const table = document.createElement('table');
            for (var i = 0; i < params.length; i++) {
              const val = params[i].value[judgments.keys[i]];

              // skip white fantastic if zero
              if (i == 1 && !val)
                continue;

              const tr = document.createElement('tr');
              const th = document.createElement('th');
              const td = document.createElement('td');

              th.style.color = params[i].color;
              th.style.textTransform = 'uppercase';
              th.style.textAlign = 'right';
              th.innerText = params[i].seriesName;

              td.style.textAlign = 'right';
              td.innerText = (val * 100).toFixed(2) + '%';

              tr.appendChild(th);
              tr.appendChild(td);
              table.appendChild(tr);
            }

            return [
              header.outerHTML,
              table.outerHTML,
            ].join('<br>');
          }
        },
        grid: {
          containLabel: true,
          left: 20,
          right: 20,
          top: 20,
          bottom: 0
        },
        xAxis: {
          type: 'time',
          min: (this.filters || {}).fromDate || 'dataMin',
          max: (this.filters || {}).toDate || 'dataMax',
          splitNumber: Math.floor(this.$refs.chart.clientWidth / 100),
          axisLine: {
            lineStyle: {
              color: 'white'
            }
          },
          minInterval: 3600 * 1000 * 24,  // one day
          axisLabel: {
            fontFamily: 'Miso',
            fontSize: 18,
            formatter: val => {
              return this.formatDate(val, {year: 'numeric'});
            }
          },
          splitLine: {
            lineStyle: {
              color: '#182025'
            }
          }
        },
        yAxis: {
          type: 'value',
          data: 'Timing',
          min: 0,
          max: 1,
          axisLine: {
            lineStyle: {
              color: 'white'
            }
          },
          axisLabel: {
            fontFamily: 'Miso',
            fontSize: 18,
            formatter: function (val) {
              return (val * 100).toFixed(2) + '%';
            }
          },
          splitLine: {
            lineStyle: {
              color: '#182025'
            }
          }
        },
        dataset: {
          source: this.points
        },
        series: [
          {
            type: 'line',
            areaStyle: {
              opacity: 1
            },
            name: judgments.labels.blueFantastic,
            stack: 'stack',
            encode: {
              x: 'fromDate',
              y: 'blueFantastic'
            },
            color: judgments.colors.blueFantastic,
            animation: false
          },
          {
            type: 'line',
            areaStyle: {
              opacity: 1
            },
            name: judgments.labels.whiteFantastic,
            stack: 'stack',
            encode: {
              x: 'fromDate',
              y: 'whiteFantastic'
            },
            color: judgments.colors.whiteFantastic,
            animation: false
          },
          {
            type: 'line',
            areaStyle: {
              opacity: 1
            },
            name: judgments.labels.excellent,
            stack: 'stack',
            encode: {
              x: 'fromDate',
              y: 'excellent'
            },
            color: judgments.colors.excellent,
            animation: false
          },
          {
            type: 'line',
            areaStyle: {
              opacity: 1
            },
            name: judgments.labels.great,
            stack: 'stack',
            encode: {
              x: 'fromDate',
              y: 'great'
            },
            color: judgments.colors.great,
            animation: false
          },
          {
            type: 'line',
            areaStyle: {
              opacity: 1
            },
            name: judgments.labels.decent,
            stack: 'stack',
            encode: {
              x: 'fromDate',
              y: 'decent'
            },
            color: judgments.colors.decent,
            animation: false
          },
          {
            type: 'line',
            areaStyle: {
              opacity: 1
            },
            name: judgments.labels.wayOff,
            stack: 'stack',
            encode: {
              x: 'fromDate',
              y: 'wayOff'
            },
            color: judgments.colors.wayOff,
            animation: false
          },
          {
            type: 'line',
            areaStyle: {
              opacity: 1
            },
            name: judgments.labels.miss,
            stack: 'stack',
            encode: {
              x: 'fromDate',
              y: 'miss'
            },
            color: judgments.colors.miss,
            animation: false
          }
        ],
      });
    },
    formatDate(date, options) {
      return this.$options.filters.date(date, {timeZone: 'UTC', ...options});
    },
    formatMonth(date) {
      return new Date(date).toLocaleString('en-US', {month: 'short', year: 'numeric', timeZone: 'UTC'});
    }
  }
}
</script>

<style scoped>
.timing-chart {
  background-color: #1e282f;
  padding: 1em;
}

.chart {
  width: 100%;
  height: 400px;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
}

@media (max-width: 960px) {
  .chart {
    height: 250px;
  }
}
</style>
