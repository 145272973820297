<template>
  <div class="scores">
    <form @submit.prevent="loadData">
      <div class="filter">
        <label>Search</label>
        <input
          v-model="form.query"
          type="text"
          class="query"
        >
      </div>
      <br>
      <div class="filter">
        <label>Date</label>
        <input
          v-model="form.fromDate"
          type="date"
        >
        &ndash;
        <input
          v-model="form.toDate"
          type="date"
          :min="form.fromDate"
        >
      </div>
      <div class="filter">
        <label>Type</label>
        <select v-model="form.stepsType">
          <option :value="null">All</option>
          <option
            v-for="stepsType in ['dance-single', 'dance-double', 'dance-couple']"
            :key="stepsType"
            :value="stepsType"
          >
            {{ stepsType | translateStepsType }}
          </option>
        </select>
      </div>
      <div class="filter">
        <label>Steps</label>
        <select v-model="form.difficulty">
          <option :value="null">All</option>
          <option
            v-for="difficulty in ['Beginner', 'Easy', 'Medium', 'Hard', 'Challenge', 'Edit']"
            :key="difficulty"
            :value="difficulty"
          >
            {{ difficulty | translateDifficulty }}
          </option>
        </select>
      </div>
      <div class="filter">
        <label>Difficulty</label>
        <input
          v-model.number="form.minMeter"
          type="number"
          min="1"
          max="30"
          class="meter"
          placeholder="min"
        >
        &ndash;
        <input
          v-model.number="form.maxMeter"
          type="number"
          :min="form.minMeter || 1"
          max="30"
          class="meter"
          placeholder="max"
        >
      </div>
      <div class="filter">
        <label>BPM</label>
        <input
          v-model.number="form.minBPM"
          type="number"
          min="0"
          max="1000"
          step="10"
          class="bpm"
          placeholder="min"
        >
        &ndash;
        <input
          v-model.number="form.maxBPM"
          type="number"
          :min="form.minBPM || 0"
          max="1000"
          step="10"
          class="bpm"
          placeholder="max"
        >
      </div>
      <div class="filter">
        <label>Grade</label>
        <select v-model="form.minGrade">
          <option
            v-for="grade in ['Tier01', 'Tier02', 'Tier03', 'Tier04', 'Tier05', 'Tier06', 'Tier07', 'Tier08', 'Tier09', 'Tier10', 'Tier11', 'Tier12', 'Tier13', 'Tier14', 'Tier15', 'Tier16', 'Tier17', 'Failed']"
            :key="grade"
            :value="grade"
          >
            {{ grade | translateGrade }}
          </option>
        </select>
        &ndash;
        <select v-model="form.maxGrade">
          <option
            v-for="grade in ['Tier01', 'Tier02', 'Tier03', 'Tier04', 'Tier05', 'Tier06', 'Tier07', 'Tier08', 'Tier09', 'Tier10', 'Tier11', 'Tier12', 'Tier13', 'Tier14', 'Tier15', 'Tier16', 'Tier17', 'Failed']"
            :key="grade"
            :value="grade"
          >
            {{ grade | translateGrade }}
          </option>
        </select>
      </div>
      <div class="filter">
        <label>%</label>
        <input
          v-model.number="form.minPercentDP"
          type="number"
          min="0"
          max="100"
          step="0.01"
          class="percent-dp"
          placeholder="min"
        >
        &ndash;
        <input
          v-model.number="form.maxPercentDP"
          type="number"
          :min="form.minPercentDP || 0"
          max="100"
          step="0.01"
          class="percent-dp"
          placeholder="max"
        >
      </div>

      <button :disabled="loading">
        <font-awesome-icon v-if="loading" icon="spinner" pulse/>
        <template v-else>Search</template>
      </button>
    </form>

    <font-awesome-icon v-if="loading" icon="spinner" pulse/>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <div v-else>
      <ScoreTable :scores="scores" :showDate="true">
        <tr>
          <td
            v-if="nextPage"
            @click="loadNextPage"
            colspan="9"
            class="next-page"
          >
            <font-awesome-icon v-if="nextPageLoading" icon="spinner" pulse/>
            <template v-else>
              Load More
              <div v-if="nextPageError" class="error">
                {{ nextPageError }}
              </div>
            </template>
          </td>
        </tr>
      </ScoreTable>
    </div>
  </div>
</template>

<script>
import ScoreTable from '@/components/ScoreTable.vue';
import FormatMixin from '@/utils/FormatMixin';

export default {
  mixins: [FormatMixin],
  components: {
    ScoreTable
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      form: {
        query: null,
        fromDate: null,
        toDate: null,
        stepsType: null,
        difficulty: null,
        minMeter: null,
        maxMeter: null,
        minBPM: null,
        maxBPM: null,
        minGrade: 'Tier17',
        maxGrade: 'Tier01',
        minPercentDP: null,
        maxPercentDP: null
      },
      scores: [],
      nextPage: null,
      loading: false,
      error: null,
      nextPageLoading: false,
      nextPageError: null
    };
  },
  methods: {
    loadData() {
      const form = {
        paginate: true,
        reverse: true
      };

      Object.keys(this.form).forEach(k => {
        if (this.form[k])
          form[k] = this.form[k];
      });

      this.loading = true;
      this.$api.post('/scores', form).then(response => {
        this.scores = response.data.scores;
        this.nextPage = response.data.nextPage;
        this.error = null;
        this.loading = false;
      }).catch(error => {
        this.error = error.message;
        this.loading = false;
      });
    },
    loadNextPage() {
      const form = {
        paginate: true,
        reverse: true,
        pageStartDatetime: this.nextPage.pageStartDatetime,
        pageStartScoreGuid: this.nextPage.pageStartScoreGuid,
      };

      Object.keys(this.form).forEach(k => {
        if (this.form[k])
          form[k] = this.form[k];
      });

      this.nextPageLoading = true;
      this.$api.post('/scores', form).then(response => {
        this.scores = this.scores.concat(response.data.scores);
        this.nextPage = response.data.nextPage;
        this.nextPageError = null;
        this.nextPageLoading = false;
      }).catch(error => {
        this.nextPageError = error.message;
        this.nextPageLoading = false;
      });
    }
  }
}
</script>

<style scoped>
.scores {
  padding: 0 1em 1em 1em;
}
@media (max-width: 960px) {
  .scores {
    padding: 0 0.5em 0.5em 0.5em;
  }
}

form {
  margin: 0.5em;
}

.filter {
  border: 1px solid #444444;
  display: inline-block;
  margin: 0.2em 0.5em 0 0;
  background-color: #1e282f;
  color: white;
  vertical-align: middle;
  white-space: nowrap;
}

label {
  padding: 0.5em;
  font-family: "Miso Bold";
}

input, select {
  border-width: 0 1px 0 1px;
}

input:last-child, select:last-child {
  border-width: 0 0 0 1px;
}

input.query {
  width: 15em;
}

input[type="date"] {
  max-width: 8em;
}

input.meter {
  width: 2.5em;
}

input.bpm {
  width: 3em;
}

input.percent-dp {
  width: 3em;
}

button {
  margin: 0.5em 1em 0.5em 1em;
}

.next-page {
  cursor: pointer;
  text-align: center;
}

.next-page:hover {
  background-color: #4c565d;
}

.error {
  color: #ff3030;
  font-family: "Miso Bold";
}
</style>
